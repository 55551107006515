.owner-dashboard-popup .MuiTextField-root {
  border: 1px solid #d1d5db;
  max-width: 150px !important;
  min-width: 0 !important;
  padding-right: 10px !important;
}

.MuiInputLabel-root.Mui-focused ~ .owner-dashboard-popup ~ .MuiInputLabel-root {
  margin-top: -20px;
}

.css-iudfg-MuiFormControl-root-MuiTextField-root{
  background-color: white;
}
.css-1v4q0y7-MuiFormControl-root-MuiTextField-root{
  background-color: white;
}

.owner-dashboard-popup .MuiStack-root {
  flex: none !important;
}
.owner-dashboard-popup .MuiInputLabel-root {
  margin-left: 12px;
  margin-top: -5px;
  font-weight: 500;
  font-size: 14px;
  /* color: #ffffffb3 !important; */
  padding: 0 2px;
}
.owner-dashboard-popup .MuiInputBase-input {
  padding: 12px 11px !important;
  margin-top: -12px;
  font-size: 14px;
}
.owner-dashboard-popup .MuiSvgIcon-root {
  margin-left: -12px;
}
.owner-dashboard-popup .MuiIconButton-root {
  margin-top: -13px !important;
  color: #1a56db;
  &:hover {
    background-color: transparent;
  }
}

.MuiTabs-scroller::-webkit-scrollbar {
  height: 0px !important;
  width: 0px !important;
}

@media screen and (max-width: 375px) {
  .owner-dashboard-popup .MuiTextField-root {
    max-width: 117px !important;
  }
}
