.sendbird-message-input .sendbird-message-input--textarea {
  border-radius: 40px !important;
}
.sendbird-theme--light .sendbird-conversation {
  border: 1px solid transparent !important;
}
.sendbird-conversation__footer__typing-indicator__text {
  margin-left: 27px !important;
}
.sendbird-chat-header .sendbird-chat-header__right__info {
  display: none !important;
}
.sendbird-theme--light .sendbird-iconbutton .sendbird-iconbutton__inner svg {
  fill: white !important;
}

/* show message sent Time always */
.sendbird-message-content.outgoing
  .sendbird-message-content__middle
  .sendbird-message-content__middle__body-container
  .sendbird-message-content__middle__body-container__created-at {
  display: flex !important;
}

/* hide context menu option */
.sendbird-message-content.outgoing
  .sendbird-message-content__left.use-reactions {
  display: none !important;
}
