.safari-form-label-focused {
  left: 12px;
  font-size: 14px;
}
.chrome-form-label-focused {
  left: 4px;
}
.safari-login-focused {
  left: 12px;
  font-size: 15px;
}
