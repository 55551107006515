/* Date-picker input styling */
.Date-picker input {
  min-width: 390px;
  width: 100% !important;
}

/* Wrapper styling for the date picker */
.date-picker-wrapper {
  position: relative;
  display: inline-block;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  font-family: Inter, sans-serif;
}

.react-calendar__month-view__days__day--neighboringMonth {
  visibility: hidden;
}

.edit-calendar-popover {
  font-family: Inter, sans-serif;
  padding: 1rem 2rem;
  background-color: white;
  position: absolute;
  top: 5rem;
  right: 0rem;
  overflow: overlay;
  overflow-y: scroll;
  overflow: scroll;
  max-height: 500px !important;
  overflow-y: initial;
  margin-right: -1.8rem !important;
  box-shadow: 1px 1px 10px lightgray;
  z-index: 1000;
  border-radius: 8px;
  overflow: hidden;
  /* display: flex; */
}

.edit-calendar-popover > .react-calendar {
  border: none;
  width: 100%;
  max-height: 330px;
  overflow-x: hidden;
  overflow-y: scroll;
  /* text-decoration: none; */
}

/* Popover styling for the calendar */
.calendar-popover {
  font-family: Inter, sans-serif;
  padding: 1rem 2rem;
  background-color: white;
  position: absolute;
  top: 5rem;
  right: 0;
  margin-right: -0.8rem;
  box-shadow: 1px 1px 10px lightgray;
  z-index: 1000;
  border-radius: 8px;
  /* Add border radius */
  overflow: hidden;
  /* Ensure content does not overflow */
  /* display: flex; */
}

/* Customize the calendar appearance */

.single_date_selection .react-calendar {
  border: none;
  width: 100%;
  /* text-decoration: none; */
}

.multi_date_selection .react-calendar {
  border: none;
  /* text-decoration: none; */
}

.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.react-calendar__navigation__label {
  font-weight: bold;
  font-size: 18px;
}

.react-calendar__navigation {
  font-size: 30px !important;
}

.react-calendar__navigation button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
  /* Hide the year navigation buttons */
}

.react-calendar__month-view__weekdays {
  display: flex;
  justify-content: space-around;
  font-weight: 500;
  font-size: 14px;
  color: #292929;
  /* Light gray for weekdays */
}

.react-calendar__month-view__weekdays__weekday {
  flex: 0 0 14.2857%;
  overflow: hidden;
  margin-inline-end: 0;
  text-transform: capitalize;
  text-align: center;
}

abbr[title] {
  text-decoration: none;
}

.react-calendar__tile {
  max-width: initial !important;
  text-align: center;
  background: none;
  border-radius: 50%;
  padding: 10px;
  font-weight: 400;
  color: #292929;
  cursor: pointer;
}

.react-calendar__month-view__days button {
  margin-bottom: 8px;
}

.react-calendar__tile:disabled {
  background-color: #f3f4f6;
  color: #d3d3d3;
  cursor: not-allowed;
}

.react-calendar__tile--now {
  background: #ffffff;
  color: #000000;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1a56db !important;
  color: white;
}

.react-calendar__tile--active {
  background: #1a56db;
  color: white;
  border-radius: 50%;
}

.react-calendar__tile--hasActive:enabled:hover {
  background: #1a56db !important;
  color: white;
}

.react-calendar__tile--range:hover {
  border-radius: 50% !important;
}

.react-calendar__tile--range {
  background: #f3f4f6;
  color: #000000;
  border-radius: 0px !important;
}

.react-calendar__tile--rangeStart {
  border-radius: 50% !important;
  background: #1a56db;
  color: white;
  font-weight: 600;
  border-radius: 50% !important;
}

.react-calendar__tile--rangeEnd {
  border-radius: 50% !important;
  background: #1a56db;
  color: white;
  font-weight: 600;
  border-radius: 50% !important;
}

.react-calendar__tile--hover {
  background: #f0f0f0;
  color: #000000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #d3d3d3;
}

.Date-picker input:focus {
  outline: none !important;
  border: none !important;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 0.8rem 0.4rem !important;
  border-radius: 1rem !important;
}

.react-calendar__year-view .react-calendar__tile--hasActive:enabled {
  border-radius: 0.8rem !important;
}

.react-calendar__decade-view .react-calendar__tile {
  padding: 0.8rem !important;
  border-radius: 1rem;
}

.multi_date_selection .react-calendar__navigation {
  min-width: 720px;
}

.multi_date_selection .react-calendar--doubleView {
  width: auto !important;
}

.react-calendar__tile--hasActive:enabled {
  background: #1a56db !important;
  color: white;
}

@media screen and (max-width: 1024px) {
  .Date-picker input {
    min-width: fit-content;
    width: 100% !important;
  }
}

@media screen and (max-width: 1200px) {
  .edit-calendar-popover.react-calendar--doubleView
    .react-calendar__viewContainer {
    flex-direction: column !important;
  }

  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 90% !important;
  }
}
@media screen and (max-width: 768px) {
  .calendar-popover {
    top: 72px;
    width: 100%;
    padding: 10px;
    min-width: auto;
    left: -18px;
    /* le/ft: 0px; */
  }
}

@media screen and (max-width: 500px) {
  .calendar-popover {
    top: 72px;
    width: 100%;
    padding: 10px;
    min-width: auto;
    /* le/ft: 0px; */
  }
}
@media screen and (max-width: 450px) {
  .calendar-popover {
    /* width: auto; */
    top: 72px;
    padding: 10px;
    min-width: 350px;
    left: -32px;
  }
}
@media screen and (max-width: 400px) {
  .calendar-popover {
    /* width: auto; */
    top: 72px;
    padding: 10px;
    min-width: 310px;
    left: -32px;
  }
}

@media screen and (max-width: 375px) {
  .calendar-popover {
    /* width: auto; */
    top: 72px;
    padding: 10px;
    min-width: 300px;
    left: -29px;
  }
}

@media screen and (max-width: 350px) {
  .calendar-popover {
    /* width: auto; */
    top: 72px;
    padding: 10px;
    min-width: 280px;
    left: -45px;
  }
}

@media screen and (max-width: 320px) {
  .calendar-popover {
    /* width: auto; */
    top: 72px;
    padding: 10px;
    min-width: 280px;
    left: -46px;
  }
}

@media screen and (max-width: 300px) {
  .calendar-popover {
    /* width: auto; */
    top: 72px;
    padding: 10px;
    min-width: 240px;
    left: -34px;
  }
}

@media screen and (max-width: 1000px) {
  .react-calendar--doubleView .react-calendar__viewContainer {
    flex-direction: column !important;
  }

  .react-calendar__navigation {
    min-width: 100% !important;
  }

  .calendar-popover {
    right: auto !important;
    margin-right: 0px !important;
  }

  .react-calendar__navigation button {
    font-size: 18px;
  }
}

/* dark mode */

.calendar-dark-mode .react-calendar__month-view__weekdays {
  color: white;
  /* White text for weekday labels */
}

.calendar-dark-mode .react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #1f1f1f00;
}

.calendar-dark-mode .react-calendar__navigation button {
  background-color: #1f1f1f00;
  color: white;
}

.calendar-dark-mode .react-calendar__navigation button:enabled:hover {
  background-color: #1f1f1f00;
}

.calendar-dark-mode .react-calendar__tile:enabled:hover {
  background-color: #1f1f1f;
}

.calendar-dark-mode .react-calendar__tile:disabled {
  background-color: #1f1f1f;
  /* Darker background for disabled state */
  color: #5f5f5f;
  /* Lighter grey text for disabled state */
  cursor: not-allowed;
}

.calendar-dark-mode .react-calendar__tile {
  color: #ffffff;
  /* White text for calendar tiles */
}

.calendar-dark-mode .react-calendar__tile--now {
  background: #3a3a3a;
  /* Darker background for the current date */
  color: #ffffff;
  /* White text for the current date */
}

.calendar-dark-mode .react-calendar__tile--active:enabled:hover,
.calendar-dark-mode .react-calendar__tile--active:enabled:focus {
  background: #1a56db !important;
  color: white;
}

.calendar-dark-mode .react-calendar__tile--active {
  background: #1a56db;
  color: white;
  border-radius: 50%;
}

.calendar-dark-mode .react-calendar__tile--hasActive:enabled:hover {
  background: #1a56db !important;
  color: white;
}

.calendar-dark-mode .react-calendar__tile--range:hover {
  border-radius: 50% !important;
}

.calendar-dark-mode .react-calendar__tile--range {
  background: #3a3a3a;
  /* Darker background for range */
  color: #ffffff;
  /* White text for range */
  border-radius: 0px !important;
}

.calendar-dark-mode .react-calendar__tile--rangeStart {
  border-radius: 50% !important;
  background: #1a56db;
  color: white;
  font-weight: 600;
}

.calendar-dark-mode .react-calendar__tile--rangeEnd {
  border-radius: 50% !important;
  background: #1a56db;
  color: white;
  font-weight: 600;
}

.calendar-dark-mode .react-calendar__tile--hover {
  background: #4a4a4a;
  /* Darker background for hover */
  color: #ffffff;
  /* White text for hover */
}

.calendar-dark-mode .react-calendar__month-view__days__day--neighboringMonth {
  color: #5f5f5f;
  /* Lighter grey text for neighboring month days */
}

.calendar-prev-button-disabled .react-calendar__navigation__prev-button {
  cursor: not-allowed;
  visibility: hidden;
}
