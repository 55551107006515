/* Hide the default radio input */
.time-dropdown input[type="radio"] {
    padding: 0;
    visibility: hidden;
  }
  
  /* Create the custom radio circle */
  .time-dropdown label {
    position: relative;
    display: inline-flex;
    align-items: center;
  }
  
  .time-dropdown label::before {
    content: '';
    display: inline-block;
    width: 1rem; /* Adjust size as needed */
    height: 1rem; /* Adjust size as needed */
    border: 2px solid #1A56DB; /* Default border color */
    border-radius: 50%;
    margin-left: .8rem;
    background-color: white;
  }


.time-dropdown   .selected::before{
    content: '';
    display: inline-block;
    width: 1rem; /* Adjust size as needed */
    height: 1rem; /* Adjust size as needed */
    border: 4px solid #1A56DB; /* Default border color */
    border-radius: 50%;
    margin-left: .8rem;
    background-color: white;
  }
/* dark */

  .dark-time-dropdown input[type="radio"] {
    padding: 0;
    visibility: hidden;
  }
  
  /* Create the custom radio circle */
  .dark-time-dropdown label {
    position: relative;
    display: inline-flex;
    align-items: center;
  }
  
  .dark-time-dropdown label::before {
    content: '';
    display: inline-block;
    width: 1rem; /* Adjust size as needed */
    height: 1rem; /* Adjust size as needed */
    border: 2px solid #1A56DB; /* Default border color */
    border-radius: 50%;
    margin-left: .8rem;
    background-color: #292929;
  }


.dark-time-dropdown   .selected::before{
    content: '';
    display: inline-block;
    width: 1rem; /* Adjust size as needed */
    height: 1rem; /* Adjust size as needed */
    border: 4px solid #1A56DB; /* Default border color */
    border-radius: 50%;
    margin-left: .8rem;
    background-color: #292929;
  }