.my-account-tab-class .MuiTabs-indicator {
    width: 0px !important;
}

.my-account-tab-class .MuiTab-iconWrapper {
    margin-bottom: 0px !important;
}

.my-account-tab-class .Mui-selected .MuiTouchRipple-root {
    color: rgb(0, 0, 0) !important;
}
