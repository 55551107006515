.react-parallax-bgimage {
    height: auto !important;
    width: 100% !important;
}


@media (min-width: 300px) {
    .react-parallax-bgimage {
        height: 70% !important;
        display: none;
    }
}


@media (min-width: 666px) {
    .react-parallax-bgimage {
        height: 100% !important;
        display: block;
    }
}


@media (min-width: 1200px) {
    .react-parallax-bgimage {
        height: auto !important;
        width: 101% !important;
        max-width: 101%;
    }
}