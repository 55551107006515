@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: inter, sans-serif;
  padding: 0 !important;
}

body.light input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #f7fbff inset;
}

body.dark input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100000px #333333 inset;
  border: none !important;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
::-webkit-scrollbar-track {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  width: 6px;
  height: 6px;
  border-radius: 4px;
  background-color: rgba(165, 165, 165, 0.726);
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

html {
  padding-right: 0 !important;
}

.customInputBase .MuiInputBase-root {
  padding-right: 0 !important;
}

/* .customFromLabel .MuiFormLabel-root .Mui-focused {
  color: #6b7280 !important;
} */
