.react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
  background-color: white;
}

.react-slideshow-container + ul.indicators {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  width: 100%;
  gap: 5px;
  justify-content: center;
  margin-top: 20px;
  bottom: 10px;
  text-align: center;
}
