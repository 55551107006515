.bookingRead-only {
    pointer-events: none;
    opacity: 0.6;
  }


  /* Hide the default radio input */
.booking-selection input[type="radio"] {
  padding: 0;
  visibility: hidden;
}

/* Create the custom radio circle */
.booking-selection label {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.booking-selection label::before {
  content: '';
  display: inline-block;
  width: 1rem; /* Adjust size as needed */
  height: 1rem; /* Adjust size as needed */
  border: 2px solid #1A56DB; /* Default border color */
  border-radius: 50%;
  margin-left: .8rem;
  background-color: white;
}


.booking-selection   .selected::before{
  content: '';
  display: inline-block;
  width: 1rem; /* Adjust size as needed */
  height: 1rem; /* Adjust size as needed */
  border: 4px solid #1A56DB; /* Default border color */
  border-radius: 50%;
  margin-left: .8rem;
  background-color: white;
}
/* dark */

.dark-booking-selection input[type="radio"] {
  padding: 0;
  visibility: hidden;
}

/* Create the custom radio circle */
.dark-booking-selection label {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.dark-booking-selection label::before {
  content: '';
  display: inline-block;
  width: 1rem; /* Adjust size as needed */
  height: 1rem; /* Adjust size as needed */
  border: 2px solid #1A56DB; /* Default border color */
  border-radius: 50%;
  margin-left: .8rem;
  background-color: #292929;
}


.dark-booking-selection   .selected::before{
  content: '';
  display: inline-block;
  width: 1rem; /* Adjust size as needed */
  height: 1rem; /* Adjust size as needed */
  border: 4px solid #1A56DB; /* Default border color */
  border-radius: 50%;
  margin-left: .8rem;
  background-color: #292929;
}