.bg-img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

@media (min-width: 300px) {
  .bg-img {
    background-image: url('./../../Assets/bgSmallImg.png');
  }
}

@media (min-width: 1280px) {
  .bg-img {
    background-image: url('./../../Assets/bgImage.png');
  }
}

@media (min-width: 1930px) {
  .bg-img {
    background-image: url('./../../Assets/bgImg3.png');
  }
}

.darkBg-img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

@media (min-width: 300px) {
  .darkBg-img {
    background-image: url('./../../Assets/bgSmallDarkImg.png');
  }
}

@media (min-width: 1280px) {
  .darkBg-img {
    background-image: url('./../../Assets/bgDarkImg.png');
  }
}

@media (min-width: 1930px) {
  .darkBg-img {
    background-image: url('./../../Assets/bgDarkLargeImg.png');
  }
}
