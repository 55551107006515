.owner-calendar > .Mui-disabled {
  background-color: #f3f4f6;
  color: #d1d5db;
  cursor: not-allowed;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.owner-calendar {
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 3rem;
  padding-top: 1rem;
  max-height: 100% !important;
  height: 100% !important;
}
.ownerCalendar-box {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}
.date-calendar-box > .MuiStack-root {
  height: 100% !important;
}
.date-calendar-box > .MuiStack-root > .MuiStack-root {
  height: 100% !important;
}
.blocked-by-user > .css-1qtrjcn-MuiButtonBase-root-MuiPickersDay-root {
  border-radius: 10000px;
  background-color: skyblue;
  color: blue;
  border: none;
}

.not-selected > .Mui-selected[aria-selected='true'] {
  background-color: transparent !important;
  color: black !important;
}

@media screen and (max-width: 1024px) {
  .owner-calendar {
    gap: 0px;
  }
  .owner-calendar {
    padding: 1.25rem !important;
  }
}

@media screen and (max-width: 324px) {
  .selectedDates-calendar > .MuiStack-root {
    height: 30px;
    width: 30px;
  }
  .MuiPickersDay-root {
    height: 30px;
    width: 30px;
  }
}
