.search-date-picker input {
  padding-right: 5px !important;
}

@media screen and (max-width: 1280px) {
  .search-date-picker .calendar-popover {
    top: 4rem !important;
  }
  .search-date-picker .date-picker-wrapper {
    width: 800px;
  }
}

@media screen and (max-width: 1000px) {
  .search-date-picker .date-picker-wrapper {
    width: fit-content;
  }
}

@media screen and (max-width: 500px) {
  .search-date-picker .calendar-popover {
    padding: 10px;
    min-width: auto;
    /* le/ft: 0px; */
  }
}

@media screen and (max-width: 425px) {
  .search-date-picker .calendar-popover {
    min-width: 340px;
    padding: 10px;
    left: 0px;
  }
}

@media screen and (max-width: 375px) {
  .search-date-picker .calendar-popover {
    padding: 10px;
    min-width: 300px;
    left: -8px;
  }
}

@media screen and (max-width: 350px) {
  .search-date-picker .calendar-popover {
    padding: 10px;
    min-width: 280px;
    left: -10px;
  }
}

@media screen and (max-width: 320px) {
  .search-date-picker .calendar-popover {
    padding: 10px;
    min-width: 280px;
    left: -20px;
  }
}

@media screen and (max-width: 300px) {
  .search-date-picker .calendar-popover {
    padding: 10px;
    min-width: 240px;
    left: -34px;
  }
}
